<ng-container *transloco="let t">
  <div *ngIf="errorMessage$ | async as errorMessage">
    <span>{{ t(errorMessage) }}.</span>
    <button
      ejs-button
      cssClass="e-btn e-primary custom-btn"
      (click)="reloadPage()"
    >
      {{ t('Try Again') }}
    </button>
  </div>
</ng-container>
