import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { tap } from 'rxjs';
import { ErrorService } from '../../services/error.service';

@Component({
  selector: 'goatsports-error-messages',
  templateUrl: './error-messages.component.html',
  styleUrls: ['./error-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslocoModule, ButtonModule],
})
export class ErrorMessagesComponent {
  errorMessage$ = this.errorService.errorMessage$;

  constructor(private errorService: ErrorService) {}

  reloadPage() {
    this.errorService.clearServerErrors();
  }
}
